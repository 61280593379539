body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: rgb(19, 41, 75);
    color: rgb(240, 240, 240);
    
}

.Header {
    padding: 2%;
    font-weight: 600;
    color: rgb(240, 240, 240);
}
.row {
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

a,
a:hover {
    text-decoration: none;
    color: rgb(240, 240, 240);
}

.Card {
    display: center;
    margin-top: 5%;
    margin-right: 2%;
    padding: 8px;
    border-radius: 8px;
    height: 200px;
}

.Card:hover {
    background: rgba(255, 255, 255, 0.12);
    border: 1px solid hsla(0, 0%, 87.8%, 0);
}

.data {
    margin-top: 10px;
}

h2 {
    font-size: 140%;
    font-weight: 500;
    margin-bottom: 0;
    letter-spacing: 1px;
}

p {
    font-size: 100%;
    font-weight: 400;
    color: rgb(104,118,140);
    margin-bottom: 5px;
}

.cover {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    height: 94px;
}

.Footer {
    font-size: x-small;
    text-align: center;
}

.Footer>p {
    font-weight: 600;
}
